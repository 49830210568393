import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SharedService {
  language: string;
  _languageValueBS = new BehaviorSubject<string>("");

  constructor() {
    this.language;

    this._languageValueBS.next(this.language);
  }

  updateComp1Val(val) {
    this.language = val;
    this._languageValueBS.next(this.language);
  }
}
