import { SideNavInterface } from "../../interfaces/side-nav.type";

export const ROUTES_CUSTOMER_DASHBOARD = [
  {
    path: "/dashboard",
    title: "Dashboard",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "dashboard",
    submenu: [],
  },
  
 {
    path: "/seo/Administration",
    title: "Administration",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "user",
    submenu: [],
  },

  {
    path: "/seo/customer-list",
    title: "Customers",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "user",
    submenu: [],
  },

  {
    path: "/seo/parking-list",
    title: "Parkings",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "car",
    submenu: [],
  },

 

  {
    path: "/seo/pack-list",
    title: "Parking packages",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "dollar-circle",
    submenu: [],
  },

  {
    path: "/seo/reservations",
    title: "Reservations",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "calendar",
    submenu: [],
  },

  {
    path: "/seo/pack-purchases",
    title: "Package purchases",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "shopping-cart",
    submenu: [],
  },

  {
    path: "/seo/Financial",
    title: "Financial",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "credit-card",
    submenu: [],
  },

  {
    path: "/seo/Deposits",
    title: "Deposits",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "wallet",
    submenu: [],
  },

  {
    path: "/seo/Payouts",
    title: "Payouts",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "wallet",
    submenu: [],
  },

 

  {
    path: "/seo/support",
    title: "Help and support",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "issues-close",
    submenu: [],
  },

  {
    path: "/seo/slot-types",
    title: "Slot types",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "setting",
    submenu: [],
  },
/**
 *  {
    path: "/seo/vehicle-types",
    title: "Vehicle types",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "article",
    submenu: [],
  },
 */
 

  {
    path: "/seo/facilities",
    title: "Facilities",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "setting",
    submenu: [],
  },

  {
    path: "/seo/Settings",
    title: "Settings",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "setting",
    submenu: [],
  },
];
