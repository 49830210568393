import { NgModule, isDevMode, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NZ_I18N, en_US } from "ng-zorro-antd/i18n";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { CommonModule } from "@angular/common";
import { ColorPickerModule } from 'ngx-color-picker';

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";

import {
  registerLocaleData,
  PathLocationStrategy,
  LocationStrategy,
  HashLocationStrategy,
} from "@angular/common";
import en from "@angular/common/locales/en";
import ar from "@angular/common/locales/ar";

import { AppRoutingModule } from "./app-routing.module";
import { TemplateModule } from "./shared/template/template.module";
import { SharedModule } from "./shared/shared.module";

import { NgChartsModule } from "ng2-charts";
import { NgApexchartsModule } from "ng-apexcharts";
import { AngularSvgIconModule } from "angular-svg-icon";
import { FullCalendarModule } from "@fullcalendar/angular";

import { AppComponent } from "./app.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";
import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";
import { ThemeConstantService } from "./shared/services/theme-constant.service";
import { ServiceWorkerModule } from "@angular/service-worker";

import { ErrorInterceptor } from "./shared/interceptor/error.interceptor";
import { ToastrModule } from "ngx-toastr";
import { JwtInterceptor } from "./shared/interceptor/token.interceptor";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { NgxSpinnerModule } from "ngx-spinner";
import { AuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire/compat";
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SharedService } from "./shared/services/shared.service";

registerLocaleData(en);
registerLocaleData(ar);

var ar_EG = {
  locale: 'ar',
  Pagination: {
      items_per_page: '/ الصفحة',
      jump_to: 'الذهاب إلى',
      jump_to_confirm: 'تأكيد',
      page: '',
      prev_page: 'الصفحة السابقة',
      next_page: 'الصفحة التالية',
      prev_5: 'خمس صفحات سابقة',
      next_5: 'خمس صفحات تالية',
      prev_3: 'ثلاث صفحات سابقة',
      next_3: 'ثلاث صفحات تالية'
  },
  DatePicker: {
      lang: {
          placeholder: 'اختيار التاريخ',
          rangePlaceholder: ['البداية', 'النهاية'],
          locale: 'ar_EG',
          today: 'اليوم',
          now: 'الأن',
          backToToday: 'العودة إلى اليوم',
          ok: 'تأكيد',
          clear: 'مسح',
          month: 'الشهر',
          year: 'السنة',
          timeSelect: 'اختيار الوقت',
          dateSelect: 'اختيار التاريخ',
          monthSelect: 'اختيار الشهر',
          yearSelect: 'اختيار السنة',
          decadeSelect: 'اختيار العقد',
          yearFormat: 'YYYY',
          dateFormat: 'M/D/YYYY',
          dayFormat: 'D',
          dateTimeFormat: 'M/D/YYYY HH:mm:ss',
          monthBeforeYear: true,
          previousMonth: 'الشهر السابق (PageUp)',
          nextMonth: 'الشهر التالى(PageDown)',
          previousYear: 'العام السابق (Control + left)',
          nextYear: 'العام التالى (Control + right)',
          previousDecade: 'العقد السابق',
          nextDecade: 'العقد التالى',
          previousCentury: 'القرن السابق',
          nextCentury: 'القرن التالى'
      },
      timePickerLocale: {
          placeholder: 'اختيار الوقت'
      },
      dateFormat: 'DD-MM-YYYY',
      monthFormat: 'MM-YYYY',
      dateTimeFormat: 'DD-MM-YYYY HH:mm:ss',
      weekFormat: 'wo-YYYY'
  },
  TimePicker: {
      placeholder: 'اختيار الوقت'
  },
  Calendar: {
      lang: {
          placeholder: 'اختيار التاريخ',
          rangePlaceholder: ['البداية', 'النهاية'],
          locale: 'ar_EG',
          today: 'اليوم',
          now: 'الأن',
          backToToday: 'العودة إلى اليوم',
          ok: 'تأكيد',
          clear: 'مسح',
          month: 'الشهر',
          year: 'السنة',
          timeSelect: 'اختيار الوقت',
          dateSelect: 'اختيار التاريخ',
          monthSelect: 'اختيار الشهر',
          yearSelect: 'اختيار السنة',
          decadeSelect: 'اختيار العقد',
          yearFormat: 'YYYY',
          dateFormat: 'M/D/YYYY',
          dayFormat: 'D',
          dateTimeFormat: 'M/D/YYYY HH:mm:ss',
          monthBeforeYear: true,
          previousMonth: 'الشهر السابق (PageUp)',
          nextMonth: 'الشهر التالى(PageDown)',
          previousYear: 'العام السابق (Control + left)',
          nextYear: 'العام التالى (Control + right)',
          previousDecade: 'العقد السابق',
          nextDecade: 'العقد التالى',
          previousCentury: 'القرن السابق',
          nextCentury: 'القرن التالى'
      },
      timePickerLocale: {
          placeholder: 'اختيار الوقت'
      },
      dateFormat: 'DD-MM-YYYY',
      monthFormat: 'MM-YYYY',
      dateTimeFormat: 'DD-MM-YYYY HH:mm:ss',
      weekFormat: 'wo-YYYY'
  },
  global: {
      placeholder: 'يرجى التحديد'
  },
  Table: {
      filterTitle: 'الفلاتر',
      filterConfirm: 'تأكيد',
      filterReset: 'إعادة ضبط',
      selectAll: 'اختيار الكل',
      selectInvert: 'إلغاء الاختيار',
      selectionAll: 'حدد جميع البيانات',
      sortTitle: 'رتب',
      expand: 'توسيع الصف',
      collapse: 'طي الصف',
      triggerDesc: 'ترتيب تنازلي',
      triggerAsc: 'ترتيب تصاعدي',
      cancelSort: 'إلغاء الترتيب'
  },
  Modal: {
      okText: 'تأكيد',
      cancelText: 'إلغاء',
      justOkText: 'تأكيد'
  },
  Popconfirm: {
      okText: 'تأكيد',
      cancelText: 'إلغاء'
  },
  Transfer: {
      searchPlaceholder: 'ابحث هنا',
      itemUnit: 'عنصر',
      itemsUnit: 'عناصر'
  },
  Upload: {
      uploading: 'جاري الرفع...',
      removeFile: 'احذف الملف',
      uploadError: 'مشكلة فى الرفع',
      previewFile: 'استعرض الملف',
      downloadFile: 'تحميل الملف'
  },
  Empty: {
      description: 'لا توجد بيانات'
  },
  Icon: {
      icon: 'أيقونة'
  },
  Text: {
      edit: 'تعديل',
      copy: 'نسخ',
      copied: 'تم النسخ',
      expand: 'المزيد'
  },
  PageHeader: {
      back: 'عودة'
  }
};

@NgModule({
  declarations: [AppComponent, CommonLayoutComponent, FullLayoutComponent],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TemplateModule,
    NgxSpinnerModule,
    SharedModule,
    NzBreadCrumbModule,
    NzSpinModule,
    ColorPickerModule,
    AuthModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyBsRjpDXndkzGLXmx1l59UtXZaSQ6EqEXQ",
      authDomain: "esourcing-5703d.firebaseapp.com",
      databaseURL:
        "https://esourcing-5703d-default-rtdb.europe-west1.firebasedatabase.app/",
      projectId: "esourcing-5703d",
      storageBucket: "esourcing-5703d.appspot.com",
      messagingSenderId: "1080392183808",
      appId: "1:1080392183808:web:06203bf4e4d2b2b84b28e5",
      measurementId: "G-982B63KC9V",
    }),
    NgChartsModule,
    NgApexchartsModule,
    HttpClientModule,
    AppRoutingModule,
    FullCalendarModule,
    MatDialogModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatTabsModule,
    AngularSvgIconModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [
    { provide: NZ_I18N, useValue: ar_EG },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },

    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ThemeConstantService,
    SharedService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}