<div class="common-layout"
    [ngClass]="{
        'is-folded': isFolded,
        'is-expand' : isExpand
    }">
    <app-header></app-header>
    <app-sidenav></app-sidenav>
    <div class="page-container bg-gray-50 dark:bg-[#010312] min-h-[100vh] ps-[280px] transition-all duration-200 ease-out [&.left-space-zero]:ps-0">
        <div class="sm:pt-[calc(70px+30px)] max-sm:pt-[calc(70px+25px)] mx-[30px] min-h-[calc(100vh-65px)]">
            <div class="flex flex-wrap items-center bg-white rounded-xl shadow-sm p-4 justify-between leading-[1.8571428571] mb-[23px] gap-y-[15px] gap-x-[30px] max-sm:flex-col" *ngIf="contentHeaderDisplay !== 'none'">
                <h4 class="text-dark dark:text-white/[.87] text-[20px] font-semibold capitalize" *ngIf="breadcrumbs$ | async; let breadcrumbs"  [translate]="(breadcrumbs[breadcrumbs.length - 1].label)"></h4>
                <nz-breadcrumb class="flex items-center modify-breadcrumb" [nzSeparator]="iconTemplate">
                    <i class="me-[5px]" nz-icon nzType="home" routerLink="/dashboard/demo-two"></i>
                    <nz-breadcrumb-item *ngFor="let breadcrumb of breadcrumbs$ | async">
                        <a class=" text-[14px] font-normal capitalize" 
                        [translate]="breadcrumb.label"
                        >
                          
                        </a>
                    </nz-breadcrumb-item>
                    <ng-template #iconTemplate><span class="inline-flex bg-light-extra relative -top-0.5 w-1 h-1 rounded-full"></span></ng-template>
                </nz-breadcrumb>
            </div>
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
   
</div>
