import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'demo-component',
    templateUrl: './components.component.html'
})
export class ComponentsComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
